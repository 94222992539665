<template>
  <div id="task-management" v-cloak v-loading.fullscreen.lock="loading">
    <div class="container">
      <div class="search-box flex-a-center">
        <div class="flex-a-center">
          <span class="font">{{ $t("search") }}</span
          >：
          <!-- <zc-search
            open_path="/"
            :address_list="address"
            title_type="task"
            title="task_management"
            :search_data="searchForm"
            callback="getData"
            :must_search="mustSearch"
          ></zc-search> -->
        </div>
        <el-input
          v-model="key"
          class="search-input"
          :placeholder="$t('taskManagement.inputKeyword')"
          @keyup.enter.native="getList(0)"
        ></el-input>
        <el-button class="search-button-1" @click="getList(0)">{{
          $t("search")
        }}</el-button>
        <el-button class="search-button-2" @click="reset">{{
          $t("reset")
        }}</el-button>
        <!-- <zc-icon-btn
          :content="$t('search')"
          btn_type="primary"
          btn_class="search_button"
          icon="icon--search"
          @click="getList(0)"
        ></zc-icon-btn>
        <zc-icon-btn
          :content="$t('reset')"
          btn_type="success"
          btn_class="search_button"
          icon="icon--zhongzhi"
          @click="reset"
        ></zc-icon-btn> -->
      </div>
      <div class="flex-between">
        <h2 class="tab-tit">
          <span>
            {{ $t("taskManagement.taskList") }}
          </span>
          <div class="all-status-box">
            <span
              v-for="(item, index) in statusOptions"
              :class="{ 'yellow-text': index === activeStatus }"
              @click="changeTaskStatus(item.val, index)"
              :key="index"
              >{{ item.name }}</span
            >
          </div>
        </h2>
        <el-popover placement="right" width="700" ref="popover" trigger="click">
          <div class="handel-popover">
            <div class="popover-left">
              <div class="popover-left-top">
                {{ $t("taskManagement.allRecord") }}
              </div>
              <el-checkbox-group
                v-model="checkListField"
                @change="changeCheck"
                class="popover-checkbox-group"
              >
                <el-checkbox
                  v-for="(item, index) in allShowColumn"
                  :label="index"
                  :key="index"
                  >{{ item.label }}
                </el-checkbox>
              </el-checkbox-group>
              <div class="popover-right-button"></div>
            </div>
            <div class="popover-right">
              <div class="popover-left-top">
                {{ $t("taskManagement.currentRecord") }}
              </div>
              <div class="popover-checkbox-group">
                <draggable
                  :list="allDisColumn"
                  group="user"
                  v-bind="dragOptions"
                >
                  <div
                    v-for="(item, index) in allDisColumn"
                    class="has-item"
                    :key="index"
                  >
                    <span>{{ item.label }}</span>
                    <i
                      class="el-icon-delete red-text"
                      @click="delField(index)"
                    ></i>
                  </div>
                </draggable>
              </div>
            </div>
          </div>
          <div class="popover-handel-box flex-center">
            <zc-icon-btn
              :content="$t('cancel')"
              btn_type="success"
              btn_class="search_button popover-left-button cancel-btn"
              icon="icon-quxiao"
              @click="cancelField"
            ></zc-icon-btn>
            <zc-icon-btn
              :content="$t('confirm')"
              btn_type="warning"
              btn_class="search_button"
              icon="icon-queren"
              @click="confirmField"
            ></zc-icon-btn>
          </div>
          <div slot="reference" @click="setUp" class="yellow-text ml20 hand">
            <i class="iconfont icon-shezhi"></i>
            <span>{{ $t("taskManagement.customColumn") }}</span>
          </div>
        </el-popover>
      </div>
      {{ $t("taskManagement.currentProject") }}
      <el-select
        v-model="SelectValue"
        placeholder="请选择"
        @change="changeSelect"
      >
        <el-option
          v-for="item in SelectOptions"
          :key="item.name"
          :label="item.name"
          :value="item.name"
        >
        </el-option>
      </el-select>
      <div
        v-if="taskListData.length"
        class="list-container"
        :style="{ width: tableWidth }"
      >
        <!-- <el-checkbox v-model="isCheckedAll"
            :indeterminate="isCheckedAllIndeterminate"
            @change="toggleCheckAll" class="toggle-checkbox"></el-checkbox> -->
        <div
          v-for="(taskItem, taskIndex) in taskListData"
          class="list-item"
          :key="taskIndex"
        >
          <div class="project-name" v-if="taskItem.name">
            {{ taskItem.name }}
          </div>
          <transition name="el-fade-in-linear">
            <el-table
              :data="taskItem.list"
              v-show="showTable[taskIndex]"
              row-key="id"
              :class="[{ firstTable: taskIndex === 0 }, 'table-con']"
              align="left"
              @select="
                (selection, row) => {
                  itemChange(selection, row, taskIndex);
                }
              "
              @select-all="selectAll($event, taskIndex)"
              :ref="'multipleTable' + taskIndex"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column
                :label="$t('taskManagement.handel')"
                width="120"
                align="left"
                class-name="handel-box"
              >
                <template slot-scope="scope">
                  <el-popover placement="left" width="200" trigger="click">
                    <div class="handel-inner">
                      <div
                        class="handel-btn"
                        @click="view(scope.row.id)"
                        v-if="rightShow('CrmTask@taskInfo')"
                      >
                        {{ $t("taskManagement.view") }}
                      </div>
                      <div
                        class="handel-btn"
                        @click="edit(scope.row.id)"
                        v-if="
                          scope.row.is_delete === 0 &&
                          scope.row.task_status != 4 &&
                          scope.row.permissions > 1 &&
                          rightShow('CrmTask@taskInfo')
                        "
                      >
                        {{ $t("edit") }}
                      </div>
                      <div
                        class="handel-btn"
                        @click="singleOperation(1, scope.row)"
                        v-if="
                          scope.row.is_delete === 0 &&
                          scope.row.task_status == 3 &&
                          scope.row.permissions > 1 &&
                          rightShow('CrmTask@finishTask')
                        "
                      >
                        {{ $t("taskManagement.complete") }}
                      </div>
                      <div
                        class="handel-btn"
                        @click="singleOperation(2, scope.row)"
                        v-if="
                          scope.row.is_delete === 0 &&
                          scope.row.task_status === 2 &&
                          scope.row.permissions > 1 &&
                          rightShow('CrmTask@startTask')
                        "
                      >
                        {{ $t("taskManagement.start") }}
                      </div>
                      <div
                        class="handel-btn"
                        @click="singleOperation(3, scope.row)"
                        v-if="
                          scope.row.is_delete === 0 &&
                          scope.row.task_status != 4 &&
                          scope.row.pid == 0 &&
                          scope.row.permissions > 1 &&
                          rightShow('CrmTask@applicationTask')
                        "
                      >
                        {{ $t("taskManagement.addSubTask") }}
                      </div>
                      <div
                        class="handel-btn"
                        @click="singleOperation(4, scope.row)"
                        v-if="
                          scope.row.is_delete === 0 &&
                          scope.row.task_status != 4 &&
                          scope.row.permissions > 1 &&
                          rightShow('CrmTask@copyTask')
                        "
                      >
                        {{ $t("taskManagement.copy") }}
                      </div>
                      <div
                        class="handel-btn"
                        @click="singleOperation(5, scope.row)"
                        v-if="
                          scope.row.is_delete === 0 &&
                          scope.row.task_status === 4 &&
                          scope.row.permissions > 1 &&
                          rightShow('CrmTask@activeTask')
                        "
                      >
                        {{ $t("taskManagement.active") }}
                      </div>
                      <div
                        class="handel-btn"
                        @click="singleOperation(6, scope.row)"
                        v-if="
                          scope.row.is_delete === 1 &&
                          scope.row.permissions > 1 &&
                          rightShow('CrmTask@restoreTask')
                        "
                      >
                        {{ $t("taskManagement.restore") }}
                      </div>
                      <div
                        class="handel-btn"
                        @click="singleOperation(7, scope.row)"
                        v-if="
                          scope.row.is_delete === 0 &&
                          scope.row.permissions > 1 &&
                          rightShow('CrmTask@deleteTask')
                        "
                      >
                        {{ $t("taskManagement.delete") }}
                      </div>
                    </div>
                    <el-button slot="reference" size="mini">
                      <i class="iconfont icon-qita"></i>
                    </el-button>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column
                :width="widthObj[item.prop]"
                class-name="can-move"
                align="left"
                show-overflow-tooltip
                v-for="(item, index) in col"
                :key="is_show == 0 ? `col_${index}` : `dro_${index}`"
                :prop="dropCol[index].prop"
                :label="dropCol[index].label"
              >
                <!-- <template slot="header" slot-scope="scope"> -->
                <template slot="header">
                  {{ item.label }}
                </template>
                <template slot-scope="scope">
                  <div
                    v-if="dropCol[index].prop == 'tags_name'"
                    class="select-tags-box"
                  >
                    <div
                      v-for="(it, index) in scope.row.tags_name"
                      :style="'background-color:' + it.value"
                      class="select-tags flex-center"
                      :key="index"
                    >
                      {{ it.name }}
                    </div>
                  </div>
                  <div
                    v-else-if="dropCol[index].prop == 'task_title'"
                    class="i-block"
                    @click="view(scope.row.id, scope.row.task_status)"
                  >
                    <el-tag v-if="scope.row.is_delete" type="info">
                      <span
                        v-if="['1', '2'].indexOf(scope.row.source_type) >= 0"
                      >
                        CRM:{{ scope.row.task_title }}
                      </span>
                      <span
                        v-else-if="
                          ['3', '4', '5', '6', '7', '8', '9'].indexOf(
                            scope.row.source_type
                          ) >= 0
                        "
                      >
                        ERP:{{ scope.row.task_title }}
                      </span>
                      <span v-else>
                        {{ scope.row.task_title }}
                      </span>
                    </el-tag>
                    <el-tag
                      v-else-if="
                        Date.parse(scope.row.want_end_time) < new Date()
                      "
                      :type="scope.row.task_status === 4 ? 'success' : 'danger'"
                    >
                      <span
                        v-if="['1', '2'].indexOf(scope.row.source_type) >= 0"
                      >
                        CRM:{{ scope.row.task_title }}
                      </span>
                      <span
                        v-else-if="
                          ['3', '4', '5', '6', '7', '8', '9'].indexOf(
                            scope.row.source_type
                          ) >= 0
                        "
                      >
                        ERP:{{ scope.row.task_title }}
                      </span>
                      <span v-else>
                        {{ scope.row.task_title }}
                      </span>
                    </el-tag>
                    <el-tag
                      v-else
                      :type="scope.row.task_status === 4 ? 'success' : ''"
                    >
                      <span
                        v-if="['1', '2'].indexOf(scope.row.source_type) >= 0"
                      >
                        CRM:{{ scope.row.task_title }}
                      </span>
                      <span
                        v-else-if="
                          ['3', '4', '5', '6', '7', '8', '9'].indexOf(
                            scope.row.source_type
                          ) >= 0
                        "
                      >
                        ERP:{{ scope.row.task_title }}
                      </span>
                      <span v-else>
                        {{ scope.row.task_title }}
                      </span>
                    </el-tag>
                    <i
                      v-if="scope.row.path"
                      class="iconfont icon-guanlian"
                      style="color: coral"
                    ></i>
                  </div>
                  <div v-else-if="dropCol[index].prop == 'task_status'">
                    <span class="gray-text" v-if="scope.row.task_status === 1">
                      {{ formateStatus(scope.row.task_status) }}</span
                    >
                    <span class="black-text" v-if="scope.row.task_status === 2">
                      {{ formateStatus(scope.row.task_status) }}</span
                    >
                    <span class="blue-text" v-if="scope.row.task_status === 3">
                      {{ formateStatus(scope.row.task_status) }}
                    </span>
                    <span class="green-text" v-if="scope.row.task_status === 4">
                      {{ formateStatus(scope.row.task_status) }}</span
                    >
                  </div>
                  <div v-else>
                    <p>{{ scope.row[dropCol[index].prop] }}</p>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </transition>
        </div>
      </div>
      <p class="no-data" v-else>
        {{ $t("taskManagement.noData") }}
      </p>
      <div class="flex handel-bottom">
        <div class="handel-inner_box">
          <div v-if="rightShow('CrmTask@applicationTask')">
            <span class="select-span mr24">
              {{ $t("taskManagement.selected") }}{{ selectNum }}/{{ total }}
            </span>
            <el-cascader
              size="mini"
              clearable
              class="mr24"
              v-model="selectType"
              :options="
                status == 1 ? allSelectOperation : changeSelectOperation
              "
              @change="dialogShow"
            ></el-cascader>
            <el-button type="primary" @click="application" size="mini">
              {{ $t("taskManagement.apply") }}
            </el-button>
          </div>
          <el-pagination
            @size-change="getList(1, searchData)"
            @current-change="getList(1, searchData)"
            :current-page.sync="page"
            :page-sizes="[20, 40, 60, 80, 100]"
            :page-size.sync="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total.sync="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :title="operationTitle"
      :visible.sync="setVisible"
      width="500px"
      v-move
      center
      class="operation-time-box"
    >
      <div class="form-item flex-center start-box">
        <div v-show="selectType[0] == 2">
          <el-date-picker
            v-model="newWantEndTime"
            type="date"
            :placeholder="$t('taskManagement.selectDatetime')"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            size="mini"
          >
          </el-date-picker>
        </div>
        <div v-show="[3, 4, 5].indexOf(selectType[0]) >= 0">
          <el-select
            v-model="selectUserArray"
            multiple
            :placeholder="$t('taskManagement.selectUser')"
            size="mini"
            class="mr10"
          >
            <el-option
              v-for="item in allUserOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div slot="footer">
        <el-button
          v-if="rightShow('CrmTask@applicationTask')"
          size="mini"
          @click="setVisible = false"
        >
          {{ $t("cancel") }}
        </el-button>
        <el-button
          v-if="rightShow('CrmTask@applicationTask')"
          type="primary"
          size="mini"
          @click="application(2)"
        >
          {{ $t("confirm") }}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('taskManagement.addSubTask')"
      :visible.sync="setSinglVisible"
      width="400px"
      v-move
    >
      <div class="flex-center">
        <div v-show="subTaskShow">
          <el-select
            v-model="selectTaskArray"
            multiple
            :placeholder="$t('taskManagement.select')"
            size="mini"
            class="mr10"
          >
            <el-option
              v-for="item in subTaskOptions"
              :key="item.id"
              :label="item.task_title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div slot="footer">
        <el-button
          v-if="rightShow('CrmTask@applicationTask')"
          size="mini"
          @click="setSinglVisible = false"
        >
          {{ $t("cancel") }}
        </el-button>
        <el-button
          v-if="rightShow('CrmTask@applicationTask')"
          type="primary"
          size="mini"
          @click="singlApplication(5)"
        >
          {{ $t("confirm") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import "@/plugins/move";
import Sortable from "sortablejs";
import draggable from "vuedraggable";
import { savePageOption } from "@/utils/common";
import { toMessage } from "@/utils/message";
import ZcIconBtn from "@/components/ZcIconBtn.vue";
// import ZcSearch from "@/components/ZcSearch.vue";

export default {
  name: "TaskManagement",
  components: {
    ZcIconBtn,
    draggable,
    // ZcSearch,
  },
  data() {
    return {
      cacheAddr: {},
      searchData: {},
      address: {},
      searchForm: [
        "任务状态",
        "任务标签",
        "我的角色",
        "逾期情况",
        "创建者",
        "负责人",
        "参与者",
        "观察者",
        "任务标签",
        "所属项目",
        "预计开始时间",
        "预计截止时间",
        "实际开始时间",
        "实际结束时间",
        "创建时间",
        "任务来源",
        "任务名称",
        "任务描述",
      ],
      mustSearch: ["任务状态", "我的角色", "逾期情况"],
      visible: false,
      setVisible: false,
      setSinglVisible: false,
      subTaskShow: false,
      SelectValue: "全部",
      SelectOptions: [],
      checkListField: [],
      copyTable: [],
      setSinglId: "",
      checkList: [],
      operationTitle: "",
      newWantEndTime: "",
      changeSelectOperation: [],
      allSelectOperation: [
        {
          label: this.$t("taskManagement.complete"),
          value: 1,
        },
        {
          label: this.$t("taskManagement.setEndDate"),
          value: 2,
        },
        {
          label: this.$t("taskManagement.addLeader"),
          value: 3,
        },
        {
          label: this.$t("taskManagement.addParticipant"),
          value: 4,
        },
        {
          label: this.$t("taskManagement.addObserver"),
          value: 5,
        },
        {
          label: this.$t("taskManagement.start"),
          value: 6,
        },
        {
          label: this.$t("taskManagement.restore"),
          value: 8,
        },
        {
          label: this.$t("taskManagement.active"),
          value: 9,
        },
        {
          label: this.$t("taskManagement.delete"),
          value: 7,
        },
      ],
      selectOperation: [
        {
          label: this.$t("taskManagement.complete"),
          value: 1,
        },
        {
          label: this.$t("taskManagement.setEndDate"),
          value: 2,
        },
        {
          label: this.$t("taskManagement.addLeader"),
          value: 3,
        },
        {
          label: this.$t("taskManagement.addParticipant"),
          value: 4,
        },
        {
          label: this.$t("taskManagement.addObserver"),
          value: 5,
        },
        {
          label: this.$t("taskManagement.start"),
          value: 6,
        },
        {
          label: this.$t("taskManagement.delete"),
          value: 7,
        },
      ],
      finishOperation: [
        {
          label: this.$t("taskManagement.active"),
          value: 9,
        },
        {
          label: this.$t("taskManagement.delete"),
          value: 7,
        },
      ],
      recyleOperation: [
        {
          label: this.$t("taskManagement.restore"),
          value: 8,
        },
      ],
      operateOptions: [
        {
          label: this.$t("taskManagement.view"),
          value: 1,
        },
        {
          label: this.$t("edit"),
          value: 2,
        },
        {
          label: this.$t("taskManagement.complete"),
          value: 3,
        },
        {
          label: this.$t("taskManagement.start"),
          value: 4,
        },
        {
          label: this.$t("taskManagement.addSubTask"),
          value: 5,
        },
        {
          label: this.$t("taskManagement.copy"),
          value: 6,
        },
        {
          label: this.$t("taskManagement.delete"),
          value: 7,
        },
      ],
      selectType: [],
      selectTaskArray: [],
      loading: false,
      page: 1,
      pageSize: 20,
      key: "",
      total: 0,
      sortProp: "",
      sortOrder: "",
      tableData: [],
      time: "",
      selectUserArray: [],
      allUserOptions: [],
      subTaskOptions: [],
      statusOptions: [
        {
          val: -1,
          name: this.$t("taskManagement.all"),
        },
        {
          val: 1,
          name: this.$t("taskManagement.uncomplete"),
        },
        {
          val: 2,
          name: this.$t("taskManagement.completed"),
        },
        {
          val: 3,
          name: this.$t("taskManagement.recycle"),
        },
      ],
      is_show: 0,
      status: 1,
      is_first: 1,
      is_manual: "",
      manualOptions: [
        {
          val: 1,
          name: "是",
        },
        {
          val: 0,
          name: "否",
        },
      ],

      searchConfig: {
        want_start_time: {
          type: "date",
          startName: this.$t("taskManagement.startDate"),
          link: this.$t("taskManagement.to"),
          endName: this.$t("taskManagement.endDate"),
          name: this.$t("taskManagement.estStartDate"),
          default: "",
        },
        want_end_time: {
          type: "date",
          startName: this.$t("taskManagement.startDate"),
          link: this.$t("taskManagement.to"),
          endName: this.$t("taskManagement.endDate"),
          name: this.$t("taskManagement.estEndDate"),
          default: "",
        },
        key: {
          type: "input",
          desc: this.$t("taskManagement.inputKeyword"),
          name: this.$t("taskManagement.taskTitle"),
          default: "",
        },
        task_status: {
          type: "select",
          desc: this.$t("taskManagement.taskStatus") + ":",
          name: this.$t("taskManagement.taskStatus"),
          option: [
            {
              label: this.$t("taskManagement.unfinished"),
              value: "1",
            },
            {
              label: this.$t("taskManagement.notStart"),
              value: "2",
            },
            {
              label: this.$t("taskManagement.working"),
              value: "3",
            },
            {
              label: this.$t("taskManagement.completed"),
              value: "4",
            },
          ],
          default: "1",
        },
        create_user: {
          type: "input",
          desc: this.$t("taskManagement.errorText"),
          name: this.$t("taskManagement.creator"),
          default: "",
        },
        charge_user: {
          type: "input",
          desc: this.$t("taskManagement.errorText1"),
          name: this.$t("taskManagement.leader"),
          default: "",
        },
        join_user: {
          type: "input",
          desc: this.$t("taskManagement.errorText2"),
          name: this.$t("taskManagement.participant"),
          default: "",
        },
        watch_user: {
          type: "input",
          desc: this.$t("taskManagement.errorText3"),
          name: this.$t("taskManagement.observer"),
          default: "",
        },
        project_id: {
          type: "select",
          desc: this.$t("taskManagement.currentProject"),
          name: this.$t("taskManagement.currentProject"),
          // option: jsonReplace("{$projectOption}"),
          default: "",
        },
        task_tag_id: {
          type: "select",
          desc: this.$t("taskManagement.tagsName"),
          name: this.$t("taskManagement.tagsName"),
          // option: jsonReplace("{$tagsGroup}"),
          default: "",
        },
      },
      allDisColumn: [],
      allShowColumn: [
        {
          label: this.$t("taskManagement.taskTitle"),
          prop: "task_title",
        },
        {
          label: this.$t("taskManagement.tagsName"),
          prop: "tags_name",
        },
        {
          label: this.$t("taskManagement.createUser"),
          prop: "create_user_name",
        },
        {
          label: this.$t("taskManagement.leader"),
          prop: "charge_user_name",
        },
        {
          label: this.$t("taskManagement.participant"),
          prop: "join_user_name",
        },
        {
          label: this.$t("taskManagement.observer"),
          prop: "watch_user_name",
        },
        {
          label: this.$t("taskManagement.taskStatus"),
          prop: "task_status",
        },
        {
          label: this.$t("taskManagement.estStartDate"),
          prop: "want_start_time",
        },
        {
          label: this.$t("taskManagement.estEndDate"),
          prop: "want_end_time",
        },
        {
          label: this.$t("taskManagement.actStartDate"),
          prop: "true_start_time",
        },
        {
          label: this.$t("taskManagement.actEndDate"),
          prop: "true_end_time",
        },
        {
          label: this.$t("taskManagement.createDate"),
          prop: "create_time",
        },
      ],
      col: [
        {
          label: this.$t("taskManagement.taskTitle"),
          prop: "task_title",
        },
        {
          label: this.$t("taskManagement.tagsName"),
          prop: "tags_name",
        },
        {
          label: this.$t("taskManagement.creator"),
          prop: "create_user_name",
        },
        {
          label: this.$t("taskManagement.leader"),
          prop: "charge_user_name",
        },
        {
          label: this.$t("taskManagement.participant"),
          prop: "join_user_name",
        },
        {
          label: this.$t("taskManagement.observer"),
          prop: "watch_user_name",
        },
        {
          label: this.$t("taskManagement.taskStatus"),
          prop: "task_status",
        },
        {
          label: this.$t("taskManagement.estStartDate"),
          prop: "want_start_time",
        },
        {
          label: this.$t("taskManagement.estEndDate"),
          prop: "want_end_time",
        },
        {
          label: this.$t("taskManagement.actStartDate"),
          prop: "true_start_time",
        },
        {
          label: this.$t("taskManagement.actEndDate"),
          prop: "true_end_time",
        },
        {
          label: this.$t("taskManagement.createDate"),
          prop: "create_time",
        },
      ],
      dropCol: [
        {
          label: this.$t("taskManagement.taskTitle"),
          prop: "task_title",
        },
        {
          label: this.$t("taskManagement.tagsName"),
          prop: "tags_name",
        },
        {
          label: this.$t("taskManagement.creator"),
          prop: "create_user_name",
        },
        {
          label: this.$t("taskManagement.leader"),
          prop: "charge_user_name",
        },
        {
          label: this.$t("taskManagement.participant"),
          prop: "join_user_name",
        },
        {
          label: this.$t("taskManagement.observer"),
          prop: "watch_user_name",
        },
        {
          label: this.$t("taskManagement.taskStatus"),
          prop: "task_status",
        },
        {
          label: this.$t("taskManagement.estStartDate"),
          prop: "want_start_time",
        },
        {
          label: this.$t("taskManagement.estEndDate"),
          prop: "want_end_time",
        },
        {
          label: this.$t("taskManagement.actStartDate"),
          prop: "true_start_time",
        },
        {
          label: this.$t("taskManagement.actEndDate"),
          prop: "true_end_time",
        },
        {
          label: this.$t("taskManagement.createDate"),
          prop: "create_time",
        },
      ],
      activeStatus: 1,
      widthObj: {
        task_title: "300",
        tags_name: "200",
        create_user_name: "200",
        charge_user_name: "200",
        join_user_name: "200",
        watch_user_name: "200",
        task_status: "100",
        want_start_time: "200",
        want_end_time: "200",
        create_time: 200,
        true_end_time: 200,
        true_start_time: 200,
      },
      pickerOptions: {
        // disabledDate(time) {
        //     return time.getTime() < Date.now();
        // },
      },
      taskListData: [],
      showTable: [],
      isCheckedAll: false,
      isCheckedAllIndeterminate: false,
      selectNum: 0,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
      };
    },
    // 计算表格宽度
    tableWidth: function () {
      let wArr = [];
      this.col.forEach((item) => {
        wArr.push(this.widthObj[item.prop]);
      });
      let temW = wArr.reduce(function (prev, cur, idx, arr) {
        if (process.env.NODE_ENV !== "production") {
          console.log(idx + arr);
        }
        return prev * 1 + cur * 1;
      });
      // 多选框以及操作栏的宽度55+120
      return temW + 175 + "px";
    },
  },
  inject: ["rightShow"],
  methods: {
    formateStatus: function (value) {
      let taskStatus = {
        1: "projectManagement.unfinished",
        2: "projectManagement.notStarted",
        3: "projectManagement.working",
        4: "projectManagement.finished",
      };
      return this.$t(taskStatus[value]);
    },
    changeSelect: function (item) {
      if (item === this.$t("taskManagement.all")) {
        this.taskListData = this.copyTable;
      } else if (item === this.$t("taskManagement.unassigned")) {
        this.taskListData = [this.copyTable[0]];
      } else {
        this.taskListData = this.copyTable.filter((i) => i.name === item);
      }
    },
    selectNumFn: function () {
      this.selectNum = 0;
      this.checkList.forEach((item) => {
        if (item.length) {
          item.forEach(() => {
            this.selectNum += 1;
          });
        }
      });
    },
    getData: function (data) {
      let ajax = {
        ...data.searchData,
      };
      this.searchData = ajax;
      if (data.type === 1) {
        this.getList(2, this.searchData);
      }
    },
    singleOperation(val, item) {
      if (val != 3) {
        let ajax = {};
        let url = "";
        ajax["id"] = item.id;
        if (val == 1) {
          //完成
          url = "/home/CrmTask/finishTask";
        } else if (val == 2) {
          //开始
          if (item.task_status && item.task_status != 2) {
            this.$message(
              toMessage("error", this.$t("taskManagement.errorText4"))
            );
            return;
          }
          if (item.charge_user_ids && item.charge_user_ids == "") {
            this.$message(
              toMessage("error", this.$t("taskManagement.errorText5"))
            );
            return;
          }
          url = "/home/CrmTask/startTask";
        } else if (val == 4) {
          //复制
          url = "/home/CrmTask/copyTask";
        } else if (val == 5) {
          //激活
          url = "/home/CrmTask/activeTask";
        } else if (val == 6) {
          //还原
          url = "/home/CrmTask/restoreTask";
        } else if (val == 7) {
          url = "/home/CrmTask/deleteTask";
        }

        this.$confirm(
          this.$t("taskManagement.confirmToApply"),
          this.$t("tips"),
          {
            confirmButtonText: this.$t("confirm"),
            cancelButtonText: this.$t("cancel"),
            type: "warning",
          }
        ).then(() => {
          this.loading = false;
          this.axios
            .post(url, ajax)
            .then((res) => {
              this.loading = false;
              if (res.data.status !== 1) {
                this.$message(toMessage("error", res.data.msg));
                return;
              }
              this.$message(toMessage());
              this.getList(0);
            })
            .catch(() => {});
        });
      }

      if (val == 3) {
        //添加子任务
        this.selectTaskArray = [];
        this.setSinglVisible = true;
        this.subTaskShow = true;
        this.setSinglId = item.id;
        this.axios
          .post("/home/crmTask/getOtherTaskList", {
            id: item.id,
          })
          .then((res) => {
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.subTaskOptions = res.data.data;
          })
          .catch(() => {});
      }
    },
    singlApplication: function (val) {
      if (val == 5) {
        //添加子任务
        let ajaxData = {};
        ajaxData["id"] = this.setSinglId;
        ajaxData["operation"] = 8;
        ajaxData["selectTaskArray"] = this.selectTaskArray;
        this.loading = false;
        this.axios
          .post("/home/CrmTask/addSubTask", ajaxData)
          .then((res) => {
            this.loading = false;
            this.setSinglVisible = false;
            this.subTaskShow = false;
            this.checkList = [];
            this.selectTaskArray = [];
            this.setSinglId = "";
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.$message(toMessage());
            this.getList(0);
          })
          .catch(() => {});
        return;
      }
    },
    setUp: function () {
      this.allDisColumn = [];
      this.checkListField = [];
      var hash = {};
      this.allShowColumn.forEach((item, index) => {
        let obj = {};
        obj["label"] = item.label;
        obj["value"] = index;
        hash[item.prop] = obj;
      });
      this.col.forEach((item) => {
        this.checkListField.push(hash[item.prop]["value"]);
        this.allDisColumn.push(hash[item.prop]);
      });
    },
    //取消编辑字段
    cancelField: function () {
      this.checkListField = [];
      this.allDisColumn = [];
      document.getElementById("task-management").click();
    },
    //完成编辑字段
    confirmField: function () {
      if (this.allDisColumn.length == 0) {
        toMessage("error", this.$t("taskManagement.errorText6"));
        return;
      }
      this.col = [];
      this.dropCol = [];
      let listCol = [];
      //this.checkListField.sort();
      this.allDisColumn.forEach((item) => {
        listCol.push(
          JSON.parse(JSON.stringify(this.allShowColumn[item["value"]]["prop"]))
        );
        this.col.push(
          JSON.parse(JSON.stringify(this.allShowColumn[item["value"]]))
        );
        this.dropCol.push(
          JSON.parse(JSON.stringify(this.allShowColumn[item["value"]]))
        );
      });
      this.listCol = listCol;
      this.checkListField = [];
      this.allDisColumn = [];
      if (this.is_show == 1) {
        this.is_show = 0;
      } else {
        this.is_show = 1;
      }
      this.visible = false;
      let obj = {};
      obj["name"] = "CrmTask_taskManagement";
      obj["option"] = listCol;
      this.axios
        .post(savePageOption(obj, "/"), obj)
        .then((res) => {
          if (res.data.status !== 1) {
            this.$message(toMessage("error", res.data.msg));
          }
        })
        .catch(() => {});
      document.getElementById("task-management").click();
    },
    //改变选项
    changeCheck: function (val) {
      this.allDisColumn = [];
      val.forEach((item) => {
        let obj = {};
        obj["label"] = this.allShowColumn[item].label;
        obj["value"] = item;
        this.allDisColumn.push(obj);
      });
    },
    //删除字段
    delField: function (index) {
      this.checkListField.splice(index, 1);
      this.allDisColumn.splice(index, 1);
    },
    //列拖拽
    columnDrop: function () {
      const wrapperTr = document.querySelector(".el-table__header-wrapper tr");
      Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        handle: ".can-move",
        onEnd: (evt) => {
          const oldItem = this.dropCol[evt.oldIndex - 1];
          this.dropCol.splice(evt.oldIndex - 1, 1);
          this.dropCol.splice(
            evt.newIndex - 1,
            0,
            JSON.parse(JSON.stringify(oldItem))
          );
          let listCol = [];
          this.dropCol.forEach((item) => {
            listCol.push(item.prop);
          });
          let obj = {};
          obj["name"] = "CrmTask_taskManagement";
          obj["option"] = listCol;
          this.axios
            .post(savePageOption(), obj)
            .then((res) => {
              if (res.data.status !== 1) {
                this.$message(toMessage("error", res.data.msg));
              }
            })
            .catch(() => {});
        },
        // 多选框禁止拖动
        onMove: (evt) => {
          if (evt.related.className.indexOf("el-table-column--selection") > 0) {
            return false;
          }
        },
      });
    },
    itemChange: function (selection, row, index) {
      let del = 0;
      if (this.checkList[index].indexOf(row.id) < 0) {
        this.checkList[index].push(row.id);
      } else {
        //del
        del = 1;
        let temIndex = this.checkList[index].indexOf(row.id);
        this.checkList[index].splice(temIndex, 1);
      }
      if (Object.prototype.hasOwnProperty.call(row, "children")) {
        row.children.forEach((sItem) => {
          if (del) {
            if (this.checkList[index].indexOf(sItem.id) >= 0) {
              //del
              let temIndex = this.checkList[index].indexOf(sItem.id);
              this.checkList[index].splice(temIndex, 1);
              this.$refs["multipleTable" + index][0].toggleRowSelection(
                sItem,
                false
              );
            }
          } else {
            if (this.checkList[index].indexOf(sItem.id) < 0) {
              this.$refs["multipleTable" + index][0].toggleRowSelection(
                sItem,
                true
              );
              this.checkList[index].push(sItem.id);
            }
          }
        });
      }
      this.selectNumFn();
    },
    //选择框全选
    selectAll: function (val, index) {
      let cL = this.checkList[index].length;
      let tL = this.taskListData[index].list.length;
      if (cL > tL) {
        // 取消全选
        this.checkList.splice(index, 1, []);
        this.taskListData[index].list.forEach((item) => {
          if (item.children) {
            item.children.forEach((sItem) => {
              this.$refs["multipleTable" + index][0].toggleRowSelection(
                sItem,
                false
              );
            });
          }
        });
      } else {
        // 全选
        this.taskListData[index].list.forEach((item) => {
          this.checkList[index].push(item.id);
          if (item.children) {
            item.children.forEach((sItem) => {
              this.checkList[index].push(sItem.id);
              this.$refs["multipleTable" + index][0].toggleRowSelection(
                sItem,
                true
              );
            });
          }
        });
      }
      this.selectNumFn();
    },
    //应用弹窗
    dialogShow: function () {
      if ([2, 3, 4, 5].indexOf(this.selectType[0]) >= 0) {
        this.setVisible = true;
        this.selectUserArray = [];
        this.newWantEndTime = "";
        let selectIndex = this.selectType[0];

        this.operationTitle = this.selectOperation[selectIndex - 1].label;
      }
    },
    //应用
    application: function (val = 0) {
      let ajax = {};
      ajax.ids = [];
      this.checkList.forEach((item) => {
        if (item.length !== 0) {
          item.forEach((sItem) => {
            ajax.ids.push(sItem);
          });
        }
      });
      if (this.selectType.length < 1) {
        this.$message(toMessage("error", this.$t("taskManagement.errorText7")));
        this.selectType = [];
        return;
      }
      if (this.checkList.length == 0) {
        this.$message(toMessage("error", this.$t("taskManagement.errorText8")));
        this.selectType = [];
        return;
      }

      ajax["operation"] = this.selectType[0];
      ajax["newWantEndTime"] = this.newWantEndTime;
      ajax["selectUserArray"] = this.selectUserArray;
      if (val === 0) {
        this.$confirm(
          this.$t("taskManagement.confirmToApply"),
          this.$t("tips"),
          {
            confirmButtonText: this.$t("confirm"),
            cancelButtonText: this.$t("cancel"),
            type: "warning",
          }
        ).then(() => {
          this.loading = false;
          this.axios
            .post("/home/CrmTask/applicationTask", ajax)
            .then((res) => {
              this.loading = false;
              if (res.data.status !== 1) {
                this.$message(toMessage("error", res.data.msg));
                return;
              }
              this.$message(toMessage());
              this.getList(0);
              this.selectType = [];
            })
            .catch(() => {});
        });
      } else {
        this.loading = false;
        this.axios
          .post("/home/CrmTask/applicationTask", ajax)
          .then((res) => {
            this.loading = false;
            this.getList(0);
            this.setVisible = false;
            this.checkList = [];
            this.selectUserArray = [];
            this.newWantEndTime = "";
            this.selectType = [];

            if (res.data.status !== 1 && res.data.status !== 2) {
              if (res.data.data.task_title) {
                this.$message(
                  toMessage("error", res.data.data.task_title + res.data.msg)
                );
              } else {
                this.$message(toMessage("error", res.data.msg));
              }
              return;
            }
            if (res.data.status === 2) {
              this.$message(toMessage("success", res.data.msg));
              this.getList(0);
              return;
            }
            this.$message(toMessage());
            this.getList(0);
          })
          .catch(() => {});
        return;
      }
    },
    addTab: function (
      option = "Index",
      action = "welCome",
      name = "首页",
      param
    ) {
      let url = "/" + option + "/" + action;
      if (param) {
        for (var i in param) {
          url += "/" + param[i];
        }
      }
      console.log(name);
      this.$router.push(url);
    },
    //查看 1
    view: function (id, status) {
      let s;
      if (status === 2) {
        s = 5;
      } else if (status === 1) {
        s = 4;
      } else {
        s = this.activeStatus;
      }
      this.addTab("CrmTask", "taskInfo", "<tags:lang>任务详情</tags:lang>", {
        id: id,
        is_view: 1,
        status: s,
      });
    },
    //编辑 1
    edit: function (id) {
      this.addTab(
        "CrmTask",
        "taskInfo",
        `<tags:lang>任务</tags:lang>-<tags:lang>edit</tags:lang>`,
        { id: id }
      );
    },
    changeTableSort: function (column) {
      this.sortProp = column.prop;
      this.sortOrder = column.order
        ? column.order == "ascending"
          ? "asc"
          : "desc"
        : "";
      this.getList(1, this.searchData);
    },
    reset: function () {
      this.key = "";
      this.date = [];
      this.task_status = "";
      this.page = 1;
      this.pageSize = 20;
      this.getList(0);
    },
    changeStatus: function (val) {
      if (process.env.NODE_ENV !== "production") {
        console.log(val);
      }
      this.getList(1, this.searchData);
    },
    getList: function (operation, ajaxData = {}, force = false) {
      let ajax = {};
      let project = this.$route.params;
      if (project.name) {
        if (project.type === 2) {
          this.status = 1;
        } else {
          // 原版无法匹配列表，但是会修改下面的值。为了能够正常使用界面，
          // 这里使用一个 force 变量在需要的时候跳过过滤。
          if (!force) {
            this.status = 0;
            this.activeStatus = 0;
          }
        }
      }
      this.page = operation === 2 ? 1 : this.page;
      if (operation == 0) {
        ajax = {
          page: this.page,
          pageSize: this.pageSize,
          key: this.key,
          sortProp: this.sortProp,
          sortOrder: this.sortOrder,
          status: this.status,
          is_first: this.is_first,
        };
      } else {
        ajax = {
          page: this.page,
          pageSize: this.pageSize,
          sortProp: this.sortProp,
          sortOrder: this.sortOrder,
          status: this.status,
          is_first: this.is_first,
          ...ajaxData,
        };
      }
      this.loading = true;
      this.axios
        .post("/home/CrmTask/getTaskList", ajax)
        .then((res) => {
          this.loading = false;
          if (res.data.status !== 1) {
            this.$message(toMessage("error", res.data.msg));
            return;
          }
          if (this.is_first) {
            if (res.data.data.habit_page) {
              let listCol = {};
              this.allShowColumn.forEach((item) => {
                listCol[item.prop] = item;
              });
              this.col = [];
              this.dropCol = [];
              res.data.data.habit_page.forEach((item) => {
                this.col.push(listCol[item]);
                this.dropCol.push(listCol[item]);
                //this.col = JSON.parse(JSON.stringify(res.data.habit_page))
                //this.dropCol = JSON.parse(JSON.stringify(res.data.habit_page))
              });
              // this.col = JSON.parse(JSON.stringify(res.data.habit_page))
              // this.dropCol = JSON.parse(JSON.stringify(res.data.habit_page))
            }
            if (this.is_show == 1) {
              this.is_show = 0;
            } else {
              this.is_show = 1;
            }
            this.is_first = 0;
          }
          let tempArr = [];
          let temTaskListData = [];
          // 根据项目id分组
          // res.data.list  = res.data.list.filter(item=>{
          //     return item.children !== undefined
          // })
          if (res.data.data.list.length > 0) {
            for (let i = 0; i < res.data.data.list.length; i++) {
              if (tempArr.indexOf(res.data.data.list[i].project_id) === -1) {
                temTaskListData.push({
                  name: res.data.data.list[i].project_name,
                  list: [res.data.data.list[i]],
                });
                tempArr.push(res.data.data.list[i].project_id);
              } else {
                for (let j = 0; j < temTaskListData.length; j++) {
                  if (
                    temTaskListData[j].name ==
                    res.data.data.list[i].project_name
                  ) {
                    temTaskListData[j].list.push(res.data.data.list[i]);
                    break;
                  }
                }
              }
            }
            if (temTaskListData.length > 0) {
              // 没有项目的放在第一个
              let nullIndex = temTaskListData.findIndex((item) => {
                return item.name === null;
              });
              let tem = JSON.parse(JSON.stringify(temTaskListData));
              if (nullIndex !== -1) {
                temTaskListData.splice(nullIndex, 1);
                temTaskListData.unshift(tem[nullIndex]);
              }
              this.taskListData = temTaskListData;
              this.taskListData.forEach(() => {
                this.showTable.push(true);
                this.checkList.push([]);
              });
            }
          } else {
            this.taskListData = [];
          }
          this.copyTable = JSON.parse(JSON.stringify(this.taskListData));
          if (this.taskListData.length > 0) {
            this.taskListData[0].name = this.$t("taskManagement.unassigned");
          }
          this.SelectOptions = [{ name: this.$t("taskManagement.all") }].concat(
            this.taskListData
          );
          if (project.name && !force) {
            console.log(project.name);
            this.SelectValue = project.name;
            this.taskListData = this.copyTable.filter(
              (i) => i.name === project.name
            );
          } else {
            if (this.SelectValue === this.$t("taskManagement.all")) {
              this.taskListData = this.copyTable;
            } else if (
              this.SelectValue === this.$t("taskManagement.unassigned")
            ) {
              this.taskListData = [this.copyTable[0]];
            } else {
              this.taskListData = this.copyTable;
            }
          }
          project = {};
          // setTabParam(project);
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
          this.allUserOptions = res.data.data.allUserOptions;
          this.isCheckedAll = false;
          this.isCheckedAllIndeterminate = false;
        })
        .catch(() => {});
    },
    del(id) {
      this.$confirm(
        this.$t("taskManagement.confirmToDelete"),
        this.$t("tips"),
        {
          confirmButtonText: this.$t("confirm"),
          cancelButtonText: this.$t("cancel"),
          type: "warning",
        }
      ).then(() => {
        this.loading = false;
        this.axios
          .post("/home/CrmTask/deleteTask", {
            id: id,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.$message(toMessage());
            this.getList(0);
          })
          .catch(() => {});
      });
    },
    // 筛选任务状态
    changeTaskStatus(val, index) {
      this.status = val;
      this.activeStatus = index;
      this.getList(0, {}, true);
    },
    // 控制项目表格隐藏显示
    toggleTable(index) {
      if (this.showTable[index]) {
        this.showTable.splice(index, 1, false);
      } else {
        this.showTable.splice(index, 1, true);
      }
    },
    showTab() {
      // let tabData = getTabParam();
      // if (tabData.isTab) {
      this.getList(0);
      // }
      // tabData.isTab = false;
      // setTabParam(tabData);
    },
  },
  watch: {
    status: function (newVal) {
      switch (newVal) {
        case -1:
          this.changeSelectOperation = this.allSelectOperation;
          break;
        case 1:
          this.changeSelectOperation = this.selectOperation;
          break;
        case 2:
          this.changeSelectOperation = this.finishOperation;
          break;
        case 3:
          this.changeSelectOperation = this.recyleOperation;
          break;
        default:
          this.changeSelectOperation = this.allSelectOperation;
          break;
      }
    },
  },
  created: function () {
    // let tabData = getTabParam();
    // if (tabData.isTab) {
    //   this.showTab();
    // } else {
    //   this.getList(0);
    // }
    this.getList(0);
  },
  beforeCreate: function () {
    this.axios.get("/public/addr.js").then((res) => {
      this.cacheAddr = JSON.parse(res.data.slice(16, -1));
      this.address = this.cacheAddr;
    });
  },
};
</script>

<style scoped>
#task-management {
  padding: 28px !important;
  overflow: auto;
  height: 100%;
}
.status-box {
  margin-top: 20px;
  margin-bottom: 20px;
}

.order-icon {
  font-size: 23px;
}

.select-tags-box {
  display: flex;
  flex-wrap: wrap;
}

.select-tags {
  height: 30px;
  display: inline-block;
  padding: 4px 14px;
  color: #fff;
  margin-right: 5px;
  font-size: 14px;
  position: relative;
  border-radius: 3px;
  margin-bottom: 3px;
  max-width: 180px;
}

.ml56 {
  margin-left: 56px;
}

.handel-bottom {
  position: fixed;
  bottom: 0;
  margin-left: -28px;
  width: 100%;
  z-index: 5;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  height: 84px;
}

.handel-inner_box {
  min-width: 1280px;
  max-width: 1700px;
  display: flex;
  align-items: center;
  background: #fff;
  padding-left: 56px;
}

.el-pagination {
  margin: 0 auto;
}

.select-span {
  font-weight: 600;
  color: #333333;
  font-size: 14px;
}

.mr24 {
  margin-right: 24px;
}

.mb24 {
  margin-bottom: 24px;
}

.recycle-btn {
  padding: 5px 16px;
  border-radius: 3px;
  text-align: center;
  font-size: 14px;
  border: 1px solid rgba(255, 87, 92, 1);
  color: #ff575c;
  cursor: pointer;
}

/* 表格手型 */
.el-table th:last-child {
  cursor: pointer;
}

/* 筛选popover */
.handel-popover {
  display: flex;
  padding: 30px;
}

.popover-left {
  width: 258px;
  margin-right: 100px;
}

.popover-left-top {
  font-weight: 600;
  font-size: 16px;
}

.popover-checkbox-group {
  margin-top: 7px;
}

.popover-checkbox-group {
  border: 1px solid rgba(28, 28, 30, 0.1);
  border-radius: 3px;
  padding: 16px;
}

.popover-checkbox-group >>> .el-checkbox {
  width: 100%;
  margin-right: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 20px;
}

.popover-checkbox-group >>> .el-checkbox:last-child {
  margin-bottom: 0;
}

.popover-checkbox-group >>> .el-checkbox__label {
  padding-left: 0;
}

.popover-checkbox-group >>> .el-checkbox .el-checkbox__label {
  color: #1c1c1e;
  font-size: 16px;
}

.popover-checkbox-group
  >>> .el-checkbox__input.is-checked
  + .el-checkbox__label {
  color: #1c1c1e;
  font-size: 16px;
}

.popover-checkbox-group >>> .el-checkbox__inner {
  width: 15px;
  height: 15px;
}

.popover-checkbox-group >>> .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #ff7315;
  border-color: #ff7315;
}

.popover-right {
  width: 258px;
}

.has-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: #1c1c1e;
  height: 19px;
  cursor: move;
}

.has-item:last-child {
  margin-bottom: 0px;
}

.popover-handel-box {
  margin-top: 5px;
  margin-bottom: 20px;
}

.popover-handel-box >>> .el-button {
  margin-right: 75px;
}

.all-status-box {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #858585;
  margin-left: 20px;
}

.all-status-box span {
  margin-right: 39px;
  cursor: pointer;
}

.container {
  min-width: 1280px;
  max-width: 1885px;
  padding-bottom: 110px;
}

.handel-inner {
  display: flex;
  flex-direction: column;
  padding: 2px;
}

.handel-btn {
  color: #333333;
  padding: 6px 10px;
  cursor: pointer;
}

.handel-btn:hover {
  color: #ff7315;
}

.tab-tit {
  margin-bottom: 19px;
  display: flex;
  align-items: baseline;
}

.red-text {
  color: #e04040 !important;
}

.project-name {
  color: #1c1c1e;
  font-size: 16px;
  margin-top: 16px;
  font-weight: 600;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
}

.project-name i {
  margin-left: 21px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

/* .list-item:not(:first-of-type) .el-table__header-wrapper{
            display: none;
        } */
.no-data {
  margin-top: 40px;
  color: #858585;
  font-size: 14px;
  text-align: center;
}

.list-container {
  position: relative;
  margin-top: 20px;
}

.toggle-checkbox {
  position: absolute;
  left: 21px;
  top: 18px;
}

/* .firstTable .has-gutter tr th:first-child .el-checkbox{
            display: none;
        } */
.operation-time-box >>> .el-dialog__body {
  min-height: 120px;
}
</style>